<template>
  <footer
    v-if="
      currentRouteName != 'home' &&
      currentRouteName != 'old' &&
      currentRouteName != '404'
    "
  >
    <div class="text-xs sm:text-sm text-center py-4">
      <ul class="md:flex md:justify-center md:items-center md:gap-6">
        <li>
          <a
            class="text-[#EC6608]"
            :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${operationId}/politique.pdf`"
            target="_blank"
            >Politiques de confidentialité</a
          >
        </li>
        <li>
          <a
            class="text-[#EC6608]"
            :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${operationId}/modalites.pdf`"
            target="_blank"
            >Mentions légales</a
          >
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      operationId: undefined,
    };
  },
  mounted() {
    const operationId = sessionStorage.getItem("operationId");
    const routename = this.currentRouteName || this.$route.name;
    let currentUrl = window.location.href;
    if (currentUrl.includes("/factures/") || currentUrl.includes("/status/")) {
    } else {
      if (!operationId) {
        this.$router.push("/");
      }
    }

    this.operationId = operationId;
  },
  watch: {
    currentRouteName: {
      immediate: true,
      handler() {
        const id = sessionStorage.getItem("_id");
        const uid = sessionStorage.getItem("uid");
        const operationId = sessionStorage.getItem("operationId");
        if (operationId) this.operationId = operationId;
        if (
          (id && uid && uid !== null && id !== null) ||
          (this.$route.params._id && this.$route.params.secu)
        ) {
          if (this.operationId == "6601a73bec4decac48184437") {
            this.homelink = `/factures/${id || this.$route.params._id}/${
              uid || this.$route.params.secu
            }`;
          } else {
            this.homelink = `/status/${id || this.$route.params._id}/${
              uid || this.$route.params.secu
            }`;
          }
        }
      },
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
