import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("./views/Register.vue"),
    },
    {
      path: "/404",
      name: "404",
      component: () => import("./views/404.vue"),
    },
    {
      path: "/form",
      name: "form",
      component: () => import("./views/Form.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue"),
    },
    {
      path: "/old",
      name: "old",
      component: () => import("./views/Old.vue"),
    },
    {
      path: "/done",
      name: "done",
      component: () => import("./views/ContactDone.vue"),
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Status.vue"),
    },
    {
      path: "/regule/:_id/:_secu",
      name: "Regule",
      component: () => import("./views/Regule.vue"),
    },
    {
      path: "/regulechantier/:_id/:_secu",
      name: "Regulechantier",
      component: () => import("./views/Regulechantier.vue"),
    },
    {
      path: "/factures/:_id/:_secu",
      name: "Factures",
      component: () => import("./views/Factures.vue"),
    },
  ],
});

export default router;
